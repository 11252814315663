import { gql } from '@apollo/client';

export const QualificationSurveyFragment = gql`
  fragment QualificationSurveyFields on QualificationSurveyQL {
    id
    buyoutAmountRange
    condition
    createdOn
    currentLoanAmountRange
    interestedInTrading
    lastUpdated
    leaseRemainingTimeRange
    ownershipStatus
    score
  }
`;
