import { Components, Theme } from '@mui/material';

export const MuiToggleButton: Components<Theme>['MuiToggleButton'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: 'none',
      fontSize: theme.typography.body2.fontSize,
      minWidth: 'max-content',
      textWrap: 'nowrap',
    }),
  },
};
