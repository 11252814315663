import * as React from 'react';

import type {
  ApolloClient,
  ApolloError,
  ApolloQueryResult,
} from '@apollo/client';
import { Query } from '@apollo/client/react/components';

import { collapseEdges, concatEdges } from '../util';
import { AUTO_COMPLETE_USERS } from './gql/autoCompleteUsers.gql';

export type AutoCompleteUsersParams = {
  searchResults?: Array<searchUsers$autocompleteUsers$edges$node>;
  error: ApolloError | null | undefined;
  loading: boolean;
  fetchMore: () => Promise<void>;
  hasMore: boolean;
};

type Props = {
  search: string;
  conversationId?: string;
  placeId?: string;
  children: (params: AutoCompleteUsersParams) => React.ReactElement | null;
};

const defaultChildProps = {
  error: undefined,
  loading: false,
  // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
  fetchMore: () => new Promise(resolve => resolve()),
  hasMore: false,
  searchResults: [],
};

export const autoCompleteUsers = ({
  client,
  search,
  conversationId,
  placeId,
  first = 10,
}: {
  client: ApolloClient<any>;
  search: string;
  conversationId?: string;
  placeId?: string;
  first?: number;
}) => {
  const fetchPolicy = !search ? 'cache-first' : 'network-only';
  return client
    .query({
      query: AUTO_COMPLETE_USERS,
      fetchPolicy: fetchPolicy,
      // Occasionally we get a flow error here...restarting flow seems to fix it
      // but there's this https://github.com/facebook/flow/issues/6321
      variables: { search, conversationId, placeId, first },
    })
    .then((results: ApolloQueryResult<searchUsers>) => {
      const { errors, data } = results;
      if (errors) {
        console.error('failed to search for users', errors);
        return [];
      }

      if (!data || !data.autocompleteUsers || !data.autocompleteUsers.edges) {
        console.error('No data in user search response', data);
        return [];
      }

      const { autocompleteUsers } = data;

      const nodes = collapseEdges<
        searchUsers$autocompleteUsers,
        searchUsers$autocompleteUsers$edges$node
      >(autocompleteUsers);

      return nodes;
    });
};

export const AutoCompleteUsers = React.memo<Props>(
  ({ search, conversationId, placeId, children }: Props) => {
    return (
      <Query<searchUsers, searchUsersVariables>
        query={AUTO_COMPLETE_USERS}
        variables={{ search, conversationId, placeId }}
        notifyOnNetworkStatusChange
        fetchPolicy="no-cache"
      >
        {({ data, error, loading, fetchMore: fetchNextPage }) => {
          const errorResult = {
            ...defaultChildProps,
            error,
            loading,
          };

          if (error) {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ error?: ApolloError; loading: any; fetc... Remove this comment to see the full error message
            return children(errorResult);
          }
          if (
            !data ||
            !data.autocompleteUsers ||
            !data.autocompleteUsers.edges
          ) {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ error?: ApolloError; loading: any; fetc... Remove this comment to see the full error message
            return children(errorResult);
          }

          const { autocompleteUsers } = data;
          const { pageInfo } = autocompleteUsers;

          const nodes = collapseEdges<
            searchUsers$autocompleteUsers,
            searchUsers$autocompleteUsers$edges$node
          >(autocompleteUsers);

          const fetchMore = async () => {
            if (!pageInfo.hasNextPage) {
              return;
            }
            try {
              await fetchNextPage({
                variables: {
                  after: pageInfo.endCursor,
                },
                // @ts-expect-error FIXME: apollo upgrade signature change
                updateQuery: (prev, { fetchMoreResult }) => ({
                  autocompleteUsers: concatEdges<
                    searchUsers$autocompleteUsers,
                    'autocompleteUsers'
                  >(prev, fetchMoreResult, 'autocompleteUsers'),
                }),
              });
            } catch {
              // likely component unmounted
              // https://github.com/apollographql/apollo-client/issues/4114
            }
          };

          return children({
            searchResults: nodes,
            fetchMore,
            loading,
            error,
            hasMore: pageInfo.hasNextPage,
          });
        }}
      </Query>
    );
  },
);
