import { Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    variant: 'outlined',
    disableElevation: true,
  },
  variants: [
    {
      props: { variant: 'link' },
      style: {
        padding: 0,
        margin: 0,
        textDecoration: 'underline',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    {
      props: { size: 'xsmall' },
      style: ({ theme }) => ({
        padding: theme.spacing(0.5),
      }),
    },
  ],
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      textTransform: 'inherit',
      fontSize:
        ownerState.size === 'large'
          ? theme.typography.body1.fontSize
          : theme.typography.body2.fontSize,
      padding:
        ownerState.size === 'large' ? theme.spacing(1.25) : theme.spacing(1),
    }),
    text: {
      textTransform: 'inherit',
    },
    contained: ({ theme, ownerState }) => ({
      padding: `calc(${
        ownerState.size === 'large' ? theme.spacing(1.25) : theme.spacing(1)
      } + 1px)`,
    }),
  },
};
