import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  type InMemoryCacheConfig,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

import { getBaseUrl } from '@numbox/util';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

import { AuthLink, RefreshTokenLink } from '../links';
import { typeDefs } from '../types';
import { CacheLogger } from '../util';
import { clientResolvers } from './clientResolvers';
import {
  mergeableTypesArray,
  TypedTypePolicies,
  possibleTypesResult,
} from '../graphql';

const LOG_APOLLO_CACHE = false;

export type ApolloState = {};

if (process.env.NODE_ENV !== 'production') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const BATCH_ENDPOINT = `${getBaseUrl()}/graphql/batch`;
const BASE_URL = `${getBaseUrl()}/graphql`;

const possibleTypes = {
  ...possibleTypesResult.possibleTypes,
  // see type policy below for explanation
  MergeableTypes: mergeableTypesArray,
};

const typePolicies: TypedTypePolicies = {
  // make all nested types merge even with no ID provided (default v2 behaviour)
  // https://medium.com/qulture-rocks-product-blog/migrating-from-apollo-client-2-to-3-9ba20783b124
  MergeableTypes: {
    merge: true,
  },
  EngagementQL: {
    merge: (existing, incoming, { mergeObjects, readField, canRead }) => {
      if (canRead(existing) && canRead(incoming)) {
        const existingSummaryId = readField('summaryId', existing);
        const incomingSummaryId = readField('summaryId', incoming);

        // If the summaryId explicitly changes and we had previously
        // loaded an engagement summary, and the new data doesn't include
        // the new summary's rating, then delete any existing rating
        const hasSummaryChanged =
          existingSummaryId !== undefined &&
          incomingSummaryId !== undefined &&
          existingSummaryId !== incomingSummaryId;

        // since incoming ratings are always for latest summary,
        // we shouldn't clear it if passed in new object
        if (hasSummaryChanged && incoming.mySummaryRating === undefined) {
          return mergeObjects(existing, {
            ...incoming,
            mySummaryRating: null,
          });
        }
      }

      return mergeObjects(existing, incoming);
    },
  },
  CallIntelligenceRatingQL: {
    keyFields: ['transcriptAnalysisId'],
  },
  Query: {
    fields: {
      // Disable merging explicitly since Teams and
      // activePhoneCallsWithoutMessages are "non-identifiable" types: lists don't have a unique ID
      // Without an explicit `merge: false`, apollo will warn about the updates we do here
      teams: {
        merge: false,
      },
      activePhoneCallsWithoutMessages: { merge: false },
    },
  },
};

export const cacheConfig: InMemoryCacheConfig = {
  possibleTypes,
  typePolicies,
};

let cache = new InMemoryCache(cacheConfig);

if (LOG_APOLLO_CACHE) {
  cache = new CacheLogger(
    {
      read: true,
      write: true,
      state: true,
    },
    cacheConfig,
  );
}

export const APOLLO_CACHE = cache;

export const AUTH_LINK = new AuthLink();
export const REFRESH_TOKEN_LINK = new RefreshTokenLink();

export const OperationHeaderLink = new ApolloLink((operation, forward) => {
  const { headers } = operation.getContext();
  operation.setContext({
    headers: {
      ...headers,
      'X-GQL-Operation': operation.operationName,
    },
  });

  return forward(operation);
});

export const GraphQLClient = new ApolloClient({
  link: ApolloLink.from([
    AUTH_LINK,
    REFRESH_TOKEN_LINK,
    OperationHeaderLink,
    ApolloLink.split(
      operation => operation.getContext().batchable,
      new BatchHttpLink({
        batchMax: 8,
        uri: BATCH_ENDPOINT,
      }),

      new HttpLink({
        uri: BASE_URL,
      }),
    ),
  ]),

  typeDefs,
  cache: APOLLO_CACHE,
  resolvers: clientResolvers,

  defaultOptions: {
    watchQuery: {
      nextFetchPolicy(currentFetchPolicy) {
        // `cache-and-network` in the V3 client will perform additional network
        // requests after the initial one. This can cause a cascade of PubNub
        // updates leading to server overload. Therefore, make sure that
        // subsequent fetches are downgraded to just reading from the cache.
        if (
          currentFetchPolicy === 'cache-and-network' ||
          currentFetchPolicy === 'network-only'
        ) {
          return 'cache-first';
        }

        // Pass-through all other fetch policies.
        return currentFetchPolicy;
      },
    },
  },
});
